import { Moment } from "moment/moment";

export enum ICropType {
  normalDates = "normalDates",
  degreeDays = "degreeDays",
}

interface DataFormat {
  dateStart: Moment | null;
  dateEnding: Moment | null;
}

export interface ITableField {
  id: number;
  name: string;
  crop_height?: number;
  starting?: string;
  ending?: string;
  date: DataFormat;
  start_degree_day?: number;
  end_degree_day?: number;
  crop_coefficient: number;
  rank: number;
}

export interface IValues {
  name: string;
  rendement: string | null;
  period: ITableField[];
  crop_type: ICropType;
  sowing_date: Moment | null;
  base_degree_day: number;
  temperature_threshold: number;
}

export interface IPeriods {
  name: string;
  start_date?: string;
  end_date?: string;
  start_degree_day: number;
  end_degree_day: number;
  sowing_date: string;
  base_degree_day: number;
  id: number;
  crop_height: number;
  crop_coefficient: number;
  rank: number;
}
