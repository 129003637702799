import { useEffect, useMemo, useState } from "react";
import { ICropType, ITableField, IValues } from "./CropsCreateTypes";
import { useDispatch } from "react-redux";
import { createCrop } from "store/crops/actions";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { FormikHelpers, useFormik } from "formik";
import CropSchema from "./CropSchema";
import { useIntl } from "react-intl";
import { ICrops } from "pages/Crops/Crops";
import { getCropDegreeDaysTemplate, getCropTemplate } from "store/crops/api";
import { forEach } from "lodash";
import moment from "moment";
import CropCreateMessages from "./CropCreateMessages";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";
import { usePrompt } from "hooks/usePromt";
import { errorNotifications } from "utils/errorNotifications";

export const getInitialFormValue = (intl: any) => {
  return {
    name: intl.formatMessage(CropCreateMessages.cropUnnamed),
    period: [],
    crop_type: ICropType.normalDates,
    sowing_date: null,
    base_degree_day: 0,
    temperature_threshold: 25,
    rendement: null,
  };
};

function CropCreateHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { client_id, project_id } = useParams();

  const [IsOpenModal, setIsOpenModal] = useState(false);
  const [IsOpenTemplateModal, setIsOpenTemplateModal] = useState(false);
  const [isEditFunc, setIsEditFunc] = useState<boolean>(false);
  const [currentPeriod, setCurrentPeriod] = useState<ITableField | undefined>();
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState<number>(-1);
  const [isATemplate, setIsATemplate] = useState<null | string>(null);

  const onEditField = async (period: ITableField, index: number) => {
    await setCurrentPeriod(period);
    await setCurrentPeriodIndex(index);
    await setIsEditFunc(true);
    await onShowModal();
  };

  const onEditSave = async (period: ITableField) => {
    const temp = [...formik.values.period];
    temp[currentPeriodIndex] = period;
    formik.setFieldValue("period", temp);
    onHideModal();
    setIsATemplate(null);
  };
  useEffect(() => {
    if (window.location.href.split("/").pop() == "create_from_template") {
      onShowTemplateModal();
    }
  }, []);
  const onAddNewPeriod = async () => {
    await setIsEditFunc(false);
    await setIsOpenModal(true);
  };
  const onShowModal = () => {
    setIsOpenModal(true);
  };
  const onHideModal = () => {
    setIsOpenModal(false);
  };
  const onShowTemplateModal = () => {
    setIsOpenTemplateModal(true);
    document.body.style.overflow = "hidden";
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onHideTemplateModal = () => {
    setIsOpenTemplateModal(false);
    document.body.style.overflow = "auto";
  };
  const onDelete = (id: number) => {
    setIsATemplate(null);
    const temp = [...formik.values.period];
    const index = temp.findIndex((element, i) => i === id);
    for (let i = index; i < temp.sort((a, b) => a.rank - b.rank).length; i++)
      temp.sort((a, b) => a.rank - b.rank)[i].rank--;
    temp.splice(index, 1);
    formik.setFieldValue("period", temp);
  };
  const onMoveUp = (index: number) => {
    if (index == 0) return;
    const tab = [...formik.values.period];
    tab.splice(index - 1, 0, tab.splice(index, 1)[0]);
    formik.setFieldValue("period", tab);
    setIsATemplate(null);
  };
  const onMoveDown = (index: number) => {
    if (index == formik.values.period.length - 1) return;
    const tab = [...formik.values.period];
    tab.splice(index + 1, 0, tab.splice(index, 1)[0]);
    formik.setFieldValue("period", tab);
    setIsATemplate(null);
  };
  const onSavePoint = (v: ITableField) => {
    const temp: ITableField[] = [...formik.values.period];
    temp.push({
      id: v.id,
      name: v.name,
      starting: v.starting,
      ending: v.ending,
      date: {
        dateStart: v.date.dateStart,
        dateEnding: v.date.dateEnding,
      },
      crop_height: v.crop_height,
      crop_coefficient: v.crop_coefficient,
      start_degree_day: v.start_degree_day,
      end_degree_day: v.end_degree_day,
      rank: temp.length + 1,
    });
    formik.setFieldValue("period", temp);

    onHideModal();
    setIsATemplate(null);
  };
  const onBack = () => {
    document.body.style.overflow = "auto";

    navigate(
      ROUTES.CROPS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  };
  const onEditPage = (id: number | string) =>
    navigate(
      ROUTES.CROPS_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", id as string)
    );

  const createCropFunc = (values: IValues, actions: FormikHelpers<IValues>) => {
    let periods: any = null;
    if (formik.values.crop_type == ICropType.normalDates)
      periods = values.period.map((period) => ({
        name: period.name,
        start_date: period.date.dateStart?.format("yyyy-MM-DD"),
        end_date: period.date.dateEnding?.format("yyyy-MM-DD"),
        crop_coefficient: period.crop_coefficient,
        crop_height: period.crop_height,
        rank: period.rank,
      }));
    else
      periods = values.period.map((period) => ({
        name: period.name,
        start_degree_day: period.start_degree_day,
        end_degree_day: period.end_degree_day,
        sowing_date: formik.values.sowing_date?.format("yyyy-MM-DD"),
        base_degree_day: formik.values.base_degree_day,
        crop_coefficient: period.crop_coefficient,
        crop_height: period.crop_height,
        rank: period.rank,
      }));
    const crop = {
      name: values.name,
      periods: periods,
      temperature_threshold: values.temperature_threshold,
      rendement: values.rendement,
    };
    dispatch(
      createCrop({
        crop_in: { ...crop },
        template_params: { name: isATemplate },
        clientId: client_id,
        projectId: project_id,
        callback: ({ data }: any) => onEditPage(data.crop.id),
        failure: (error: any) => {
          errorNotifications(error);
          actions.setSubmitting(false);
        },
        finallyCallback: () => {
          actions.setSubmitting(false);
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.crop),
            }),
          });
        },
      })
    );
  };

  const cropTypeOptions = [
    {
      value: "normalDates",
      label: "Dates",
    },
    {
      value: "degreeDays",
      label: "Degree Days",
    },
  ];

  const [cropTemplates, setCropTemplates] = useState<ICrops[]>(Array());
  useEffect(() => {
    getCropTemplate().then(({ data }) => {
      setCropTemplates(data);
    });
  }, []);

  const [cropDegreeDaysTemplates, setCropDegreeDaysTemplates] = useState<
    ICrops[]
  >(Array());
  useEffect(() => {
    getCropDegreeDaysTemplate().then(({ data }) => {
      setCropDegreeDaysTemplates(data);
    });
  }, []);

  const formik = useFormik<IValues>({
    validationSchema: CropSchema(intl),
    onSubmit: createCropFunc,
    initialValues: getInitialFormValue(intl),
  });

  useEffect(() => {
    formik.setFieldValue("period", Array());
    formik.setFieldValue(
      "base_degree_day",
      getInitialFormValue(intl).base_degree_day
    );
    formik.setFieldValue("sowing_date", getInitialFormValue(intl).sowing_date);
  }, [formik.values.crop_type]);

  const onSelectCropTemplate = (template: ICrops) => {
    let periods: any = null;
    formik.setFieldValue("name", template.name);
    formik.setFieldValue("temperature_threshold", 25);
    setIsATemplate(template.name);
    if (formik.values.crop_type == ICropType.normalDates)
      periods = template.periods.map((period) => ({
        ...period,
        start_date: period.start_date,
        end: period.end_date,
        crop_coefficient: period.crop_coefficient,
        crop_height: period.crop_height,
        rank: period.rank,
      }));
    else
      periods = template.periods.map((period) => ({
        ...period,
        name: period.name,
        start_degree_day: period.start_degree_day,
        end_degree_day: period.end_degree_day,
        sowing_date: period.sowing_date,
        base_degree_day: period.base_degree_day,
        crop_coefficient: period.crop_coefficient,
        crop_height: period.crop_height,
        rank: period.rank,
      }));

    const crop = {
      name: template.name,
      temperature_threshold: 25,
      periods: periods,
      rendement: template.rendement,
    };
    dispatch(
      createCrop({
        crop_in: { ...crop },
        template_params: { name: template.name },
        clientId: client_id,
        projectId: project_id,
        callback: ({ data }: any) => onEditPage(data.crop.id),
        finallyCallback: () => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.crop),
            }),
          });
          onHideTemplateModal();
        },
      })
    );
  };

  const onSelect = (key: keyof IValues) => (option: any) => {
    formik.setFieldValue(key, option.value);
    formik.setFieldValue("temperature_threshold", 25);
  };

  return {
    models: {
      IsOpenModal,
      IsOpenTemplateModal,
      isEditFunc,
      cropTemplates,
      cropDegreeDaysTemplates,
      currentPeriod,
      client_id,
      project_id,
      formik,
      cropTypeOptions,
    },
    commands: {
      onBack,
      onSavePoint,
      onDelete,
      onAddNewPeriod,
      onEditSave,
      onEditField,
      onHideModal,
      onShowTemplateModal,
      onHideTemplateModal,
      onMoveDown,
      onMoveUp,
      onSelectCropTemplate,
      onSelect,
    },
  };
}

export { CropCreateHook };
