import React, { useState } from "react";
import styled from "./styled.module.scss";
import Header from "components/Header/Header";
import Button from "components/common/button/Button";
import ProjectCard from "components/ProjectCard/ProjectCard";
import SortDropdown from "components/SortDropdown/SortDropdown";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import ProjectModal from "components/ProjectModal/ProjectModal";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectById,
  getProjectsListState,
  getProjectsLoadingState,
} from "store/projects/selectors";
import { useParams } from "react-router-dom";
import { getClientById } from "store/clients/selectors";
import { deleteProject } from "store/projects/actions";
import withAuthProtection from "hoc/authProtection";
import { Loader } from "components/common/loader/Loader";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import DirectoryModal from "components/DirectoryModal/DirectoryModal";
import {
  getDirectoryById,
  getDirectoryListState,
  getDirectoryLoadingState,
} from "store/directorys/selectors";
import DirectoryCard from "components/DirectoryCard/DirectoryCard";
import { deleteDirectory, linkProject } from "store/directorys/actions";
import { printVarsHook } from "utils/printVarsHook";

const ProjectsList = () => {
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const projectList = useSelector(getProjectsListState);
  const directoryList = useSelector(getDirectoryListState);
  const isLoading = useSelector(getProjectsLoadingState);
  const isDirectoryLoading = useSelector(getDirectoryLoadingState);
  const currentClient = useSelector(getClientById(client_id));

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowDirectoryModal, setIsShowDirectoryModal] = useState(false);
  const [editId, setEditId] = useState<string | number | undefined>(undefined);
  const [editDirectoryId, setEditDirectoryId] = useState<string | number | undefined>(undefined);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteDirectoryId, setDeleteDirectoryId] = useState<number | null>(null);
  const editProject = useSelector(getProjectById(editId));
  const editDirectory = useSelector(getDirectoryById(editDirectoryId));

  const onHideWarning = () => setDeleteId(null);
  const onHideWarningDirectory = () => setDeleteDirectoryId(null);

  const onShowWarning = (id: number) => () => {
    setDeleteId(id);
  };
  const onShowDirectoryWarning = (id: number) => () => {
    setDeleteDirectoryId(id);
  };

  const onHideModal = () => {
    setIsShowModal(false);
    setEditId(undefined);
  };
  const onHideDirectoryModal = () => {
    setIsShowDirectoryModal(false);
    setEditDirectoryId(undefined);
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onShowDirectoryModal = () => {
    setIsShowDirectoryModal(true);
  };

  const onEdit = (id: string | number) => () => {
    setEditId(id);
    setIsShowModal(true);
  };

  const onDirectoryEdit = (id: string | number) => () => {
    setEditDirectoryId(id);
    setIsShowDirectoryModal(true);
  };

  const onDelete = () => {
    dispatch(
      deleteProject({
        clientId: client_id,
        projectId: deleteId,
        callback: onHideWarning,
      })
    );
  };

  const onDeleteDirectory = () => {
    dispatch(
      deleteDirectory({
        clientId: client_id,
        id: deleteDirectoryId,
        callback: onHideWarningDirectory,
      })
    );
  };

  const [sortValue, setSortValue] = useState<any>(null)

  printVarsHook(sortValue, "sortValue")

  const onLinkProject = (directory_id: number) => ({ id }: any) => {
    dispatch(
      linkProject({
        clientId: client_id,
        id: directory_id,
        project_id: id,
        callback: onHideWarningDirectory,
      })
    );
  };

  const linkedProjectIds = new Set(
    directoryList.flatMap((directory: any) =>
      directory.directory_links.map((link: any) => link.project_id)
    )
  );

  let list = [
    ...projectList
      .filter((project: any) => !linkedProjectIds.has(project.id))
      .map((project: any) => ({ type: "project", obj: project })),
    ...directoryList.map((directory: any) => ({ type: "directory", obj: directory })),
  ];

  if (sortValue) {
    if (sortValue.action.order_by == "name")
      if (sortValue.action.order == "asc")
        list.sort((a: any, b: any) => a.obj.name.localeCompare(b.obj.name));
      else
        list.sort((a: any, b: any) => b.obj.name.localeCompare(a.obj.name));
    if (sortValue.action.order_by == "created_at")
      if (sortValue.action.order == "asc")
        list.sort(
          (a: any, b: any) =>
            new Date(b.obj.updated_at).getTime() - new Date(a.obj.updated_at).getTime()
        );
      else
        list.sort(
          (a: any, b: any) =>
            new Date(a.obj.updated_at).getTime() - new Date(b.obj.updated_at).getTime()
        );
  }

  return (
    <>
      <Header />
      <main className={styled.wrapper}>
        <section className={styled.headerSection}>
          <div className={styled.row}>
            <h1 className={styled.title}>
              {currentClient?.name}{" "}
              <span className={styled.gray}>
                <FormattedMessage {...commonMessages.projects} />
              </span>
            </h1>

            <div className={styled.control}>
              <SortDropdown type="project" second_value={setSortValue} />
              <Button onClick={onShowModal} iconBefore={<Plus />}>
                <FormattedMessage {...commonMessages.createNew} />
              </Button>
            </div>
          </div>
          <Button onClick={onShowDirectoryModal} iconBefore={<Plus />} style={{ height: "30px", marginLeft: "auto" }}>
            <FormattedMessage {...commonMessages.createNewDirectory} />
          </Button>

          <p className={styled.subtitle}>{currentClient?.description}</p>
        </section>
        {isLoading || isDirectoryLoading ? (
          <Loader />
        ) : (
          <section className={styled.projectsSection}>
            {list.map(({ type, obj }, index) => {
              if (type === "project") {
                return (
                  <ProjectCard
                    key={index}
                    project={obj}
                    onDelete={onShowWarning(obj.id)}
                    onEdit={onEdit(obj.id)}
                  />
                );
              } else {
                return (
                  <DirectoryCard
                    key={index}
                    directory={obj}
                    onEdit={onDirectoryEdit(obj.id)}
                    onDelete={onShowDirectoryWarning(obj.id)}
                    onDrop={onLinkProject(obj.id)}
                    projects={projectList.filter((project: any) =>
                      new Set(obj.directory_links.map((item: any) => item.project_id)).has(project.id)
                    )}
                    onDeleteProject={onShowWarning}
                    onEditProject={onEdit}
                  />
                );
              }
            })}
          </section>
        )}
      </main>

      <ProjectModal
        show={isShowModal}
        onHide={onHideModal}
        editId={editId}
        utc={editId ? editProject.utc : undefined}
        {...(editId && { initialValues: editProject })}
      />
      <DirectoryModal
        show={isShowDirectoryModal}
        onHide={onHideDirectoryModal}
        editId={editDirectoryId}
        {...(editDirectoryId && { initialValues: editDirectory })}
      />
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDelete}
        show={deleteId !== null}
        onHide={onHideWarning}
      />
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDeleteDirectory}
        show={deleteDirectoryId !== null}
        onHide={onHideWarningDirectory}
      />
    </>
  );
};

export default withAuthProtection(ProjectsList);
